import React from 'react'
import { images } from 'config/images'

export const SoftwareDevSerProps = {
  ppcbannerProps: {
    heading: (
      <>
        Building businesses of tomorrow with{' '}
        <span className="highlight-head">Software Development Services</span>
      </>
    ),
    para: (
      <>
        Transform your business goals into reality with{' '}
        <span className="color-through">modern languages and frameworks.</span>{' '}
        When it comes to building businesses with software, our tech experts can
        help you surpass your goals.
      </>
    ),
    formCTA: 'Talk to our experts',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Trusted and tested by many, for{' '}
        <span className="highlight-head">high quality</span> and performance
      </>
    ),
    buildAppPara: (
      <>
        We are with you from idea to execution. We invest in quality talent who
        understand{' '}
        <span className="color-through">building businesses with tech.</span>
        <br />
        Watch the power of software turn your goals into a beautiful reality
        with us. So why go anywhere else when you can meet all your requirements
        under one roof?
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Key <span className="highlight-head">Software Development</span>{' '}
        Offerings
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Technology Consulting',
        serviceParagraph: (
          <>
            This is a partnership. <strong>Your goals are ours,</strong> your
            needs drive our direction. We listen to your objectives, challenges,
            and hopes so we can align on processes, technologies, or teams to
            invest in.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Web and Mobile App Development',
        serviceParagraph: (
          <>
            Close the gap between imagined possibility and reality by{' '}
            <strong>building high performant, scalable</strong> web and mobile
            apps using modern languages and frameworks.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Mobile',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'API Development and Integration',
        serviceParagraph: (
          <>
            <strong>Connect existing systems</strong> and applications by
            building APIs for mobile, cloud, web sockets, browsers, and
            databases to facilitate access to application data, features, and
            business logic.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'Manual',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'Re-engineering and Performance Tuning',
        serviceParagraph: (
          <>
            Growing businesses face scalability and performance issues. We
            become your extended team to{' '}
            <strong>improve the architecture,</strong> refactor old code,
            improve deployment and API integrations.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Software',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'Quality Assurance and Software Testing',
        serviceParagraph: (
          <>
            We don’t just test functionality or match developed features with
            your requirements, we <strong>test to ensure</strong> that users
            will be able to use the application as intended.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'DevOps',
        serviceClass: 'ice',
      },
      {
        id: '6',
        serviceHeading: 'Continuous Integration and Deployment',
        serviceParagraph: (
          <>
            Our DevOps team will work with your team to establish detailed{' '}
            <strong>deployment objectives and timelines,</strong> covering
            configuration, testing, governance, troubleshooting and more.
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Integration',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with <br />
        Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Strategy and consulting to set the right goals',
        workingBenefitsContent: (
          <>
            We co-create apps by understanding your business needs first. We
            identify processes that can be streamlined and build a{' '}
            <strong>tech delivery roadmap</strong> to align with your goals.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Product development approach to mobile apps',
        workingBenefitsContent: (
          <>
            Our team takes your ideas and turns them into apps with{' '}
            <strong>high ROI and retention rates</strong>. Our UX driven
            approach with user research, usability testing, and design thinking
            ensure an engaging app.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Incorporate Security Best Practices',
        workingBenefitsContent: (
          <>
            Mobile app <strong>testing best practice </strong> reduces the
            security risks, tests potential vulnerabilities and incorporates
            data encryption. This ensures that an application, APIs and its data
            is secure from potential attacks.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Recent <span className="highlight-head">Case studies</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },

  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/build-ecommerce-web-application-node-js/',
        featurecardTitle:
          'How to Build an eCommerce Web Application using Node.js?',
      },
      {
        blogLink: 'https://www.simform.com/what-is-tdd/',
        featurecardTitle: 'What is TDD?',
      },
      {
        blogLink: 'https://www.simform.com/test-automation-strategy/',
        featurecardTitle: 'How to Build a Test Automation Strategy?',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactMichaelCullinan,
    personImg2x: images.contactMichaelCullinan2x,
    personName: 'Michel Cullian',
    personDesignation:
      'Creating UX driven software products to build a growth engine for your organization.',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
